// Libraries
import React from "react"
import { CONTACT_EMAIL, CONTACT_PHONE } from "../../../../utils/contact"
import Button from "../buttons/Button"

// Styles
import "./style.scss"

export default function Index() {
  return (
    <div className="map " id="Ubicacion">
      <div className="map__container wrapper">
        <div className="map__container-wrap ">
          <h2 className="landing__title" data-aos="fade-right">
            Ubicación
          </h2>
          <h5 className="map__container-wrap-title" data-aos="fade-right">
            Estamos ubicados en <span>Zonamérica</span>, importante centro financiero de Uruguay.
          </h5>
          <p className="map__container-wrap-description" data-aos="fade-right">
            Contáctanos para conocernos y ver cómo podemos colaborar juntos y ayudarte a hacer crecer tu patrimonio.
          </p>
          <div className="map__container-wrap-contact">
            <p data-aos="fade-right">
              Envíanos un correo a <br />
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </p>

            <p data-aos="fade-right">
              Teléfono: <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
            </p>
          </div>

          <a
            href="https://www.google.com/maps/place/Zonamerica/@-34.7912509,-56.0731899,17z/data=!3m1!4b1!4m5!3m4!1s0x95a0290108ed868d:0xca8e3961ebff487f!8m2!3d-34.7912509!4d-56.0710012"
            target="_blank"
            rel="noreferrer"
            className="map__container-wrap-redirect"
            data-aos="fade-right">
            Ruta 8 Km 17.500, Zonamerica, Edificio Celebra, Oficina 203, Montevideo, Uruguay.
          </a>
          <div className="map__container-wrap-buttons" data-aos="fade-up">
            <a
              href="https://www.google.com/maps/dir//Zonamerica,+Ruta+8+km+17.500,+91600+Montevideo,+Departamento+de+Montevideo,+Uruguay/@-34.7912509,-56.0710012,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x95a0290108ed868d:0xca8e3961ebff487f!2m2!1d-56.0710012!2d-34.7912509!3e0"
              target="_blank"
              rel="noopener noreferrer">
              <Button variant="contained">CÓMO LLEGAR</Button>
            </a>
            {/* <a href="#Contacto"> */}
            <a href="mailto:contacto@globalfolio.com.uy">
              <Button>CONTACTO</Button>
            </a>
          </div>
        </div>
        <div className="map__container-wrap" data-aos="fade-up">
          <div className="map__container-wrap-iframe">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.5904815138038!2d-56.07342594294263!3d-34.79108339551734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a0290108ed868d%3A0xca8e3961ebff487f!2sZonamerica!5e0!3m2!1ses-419!2scl!4v1672851258652!5m2!1ses-419!2scl"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// Libraries
import React from "react"

// Components
import AppRouter from "./router/index"

const App = () => {
  return <AppRouter />
}

export default App

// LIBRARIES
import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

// ROUTE WRAPPER
import RouteWrapper from "./RouteWrapper"

// LAYOUTS
import EmptyLayout from "../layouts/EmptyLayout"

// LANDING
import Landing from "../pages/landing/index"

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWrapper exact path="/" component={Landing} layout={EmptyLayout} />

        {/* 404 */}
        <RouteWrapper path="*" component={Landing} layout={EmptyLayout} />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter

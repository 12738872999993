// Libraries
import React, { useState, useEffect } from "react"

// Assets
import ahirmas from "../../../../assets/images/landing/team/ahirmas.png"
import ftorres from "../../../../assets/images/landing/team/ftorres.png"
import mjmussio from "../../../../assets/images/landing/team/mjmussio.png"
import cecheverria from "../../../../assets/images/landing/team/cecheverria.png"
import jsalame from "../../../../assets/images/landing/team/jsalame.png"
// import team_2 from "../../../../assets/images/landing/team/team_2.jpg"
// import team_3 from "../../../../assets/images/landing/team/team_3.jpg"

// Styles
import "./style.scss"
import TeamCard from "./TeamCard"

const team = [
  {
    name: "Alejandro",
    lastname: "Hirmas",
    image: ahirmas,
    description: `${
      new Date().getFullYear() - 1988
    } años de experiencia en Banca e Inversiones. MBA The Wharton School, University of Pennsylvania. Ingeniero Comercial Universidad Católica de Chile. Previamente desempeñó diversos cargos en el Grupo Penta y Banco Chile New York.`,
  },
  {
    name: "Facundo",
    lastname: "Torres",
    image: ftorres,
    description: `${
      new Date().getFullYear() - 2000
    } años de experiencia en Banca e Inversiones. Ingeniero Comercial PUC y Magister en Economía PUC. Previamente desempeñó diversos cargos de Gerente de Inversiones en Devon y Celfin Capital AGF y Gerente de Estudios en PE&I.`,
  },
  {
    name: "María Jesús",
    lastname: "Mussio",
    image: mjmussio,
    description: `${
      new Date().getFullYear() - 2019
    } años de experiencia en “Compliance”. Contadora Pública, Universidad ORT. Previamente se desempeñó en consultoría de temas de cumplimiento y normativa banco-centralista.`,
  },
]
const partners = [
  team[0],
  {
    name: "Cristián",
    lastname: "Echeverría",
    image: cecheverria,
    description: `${new Date().getFullYear() - 1986} años de experiencia en Banca e Inversiones. Previamente desempeñó diversos cargos en Bank Boston y American Express Bank.`,
  },
  {
    name: "Jorge",
    lastname: "Salamé",
    image: jsalame,
    description: `${
      new Date().getFullYear() - 1989
    } años de experiencia en Banca e Inversiones. Ingeniero Comercial con mención en Finanzas y Negocios Internacionales, The Florida State University. Previamente desempeñó diversos cargos en American Express Bank.`,
  },
  team[1],
  // {
  //   name: "",
  //   lastname: "",
  //   image: team_1,
  //   description: "",
  // },
]
const selectors = [
  {
    label: "Equipo",
    data: team,
  },
  {
    label: "Socios",
    data: partners,
  },
]
export default function Index() {
  const [option, setOption] = useState(0)

  useEffect(() => {
    try {
      document.getElementsByClassName("Equipo-home")[0].addEventListener("click", () => setOption(0))
      document.getElementsByClassName("Equipo-navbar")[0].addEventListener("click", () => setOption(0))
      document.getElementsByClassName("Equipo-navbar-drawer")[0].addEventListener("click", () => setOption(0))
      document.getElementsByClassName("Equipo-footer")[0].addEventListener("click", () => setOption(0))
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className="team" id="Equipo">
      <div className="wrapper">
        <div className="team__navbar" data-aos="fade-up">
          <h3 className="landing__title">{option === 0 ? "Nuestro equipo" : "Socios"}</h3>
          <div className="team__navbar-options">
            {selectors.map(({ label }, i) => (
              <span key={i} onClick={() => setOption(i)} className={`team__navbar-options-option ${option === i ? "active" : ""}`}>
                {label}
              </span>
            ))}
          </div>
        </div>
        <div className="team__body">
          {selectors[option].data.map((member, i) => (
            <TeamCard index={i} member={member} />
            // <div key={i} className="team__body-card">
            //   <div className="team__body-card-header">
            //     <img src={team_1} alt="Presidente" />
            //     <h3>
            //       {member.name} {member.lastname}
            //     </h3>
            //   </div>
            // </div>
          ))}
        </div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-has-content */
// Components
import { menuItems } from "../../../pages/landing/components/home"

// Assets
// import formulario_reclamos from "../../../assets/files/formulario_reclamos.pdf"

import "./NavbarDrawer.scss"
const NavbarDrawer = ({ handlerOpenrNavDrawer = () => {}, showNavbarDrawer = false }) => {
  return (
    <main className="NavbarDrawer" style={{ transform: `translateX(${showNavbarDrawer ? 0 : "200%"})` }}>
      <div></div>
      <ul className="NavbarDrawer__List">
        {menuItems.map((item, i) => (
          <li key={i} className={`NavbarDrawer__List__Item ${item.className}-navbar-drawer`}>
            <a onClick={() => handlerOpenrNavDrawer()} {...item} />
          </li>
        ))}
      </ul>

      <a onClick={() => handlerOpenrNavDrawer()} className="NavbarDrawer__List__Item Legales-navbar-claims" href="#Legales">
        Reclamos
      </a>
    </main>
  )
}

export default NavbarDrawer

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useEffect, useState } from "react"
import { Rotate as Hamburger } from "hamburger-react"
import NavbarDrawer from "./components/NavbarDrawer"
import logo from "../../assets/images/landing/navbarLogo.svg"
import { menuItems } from "../../pages/landing/components/home"
import "./Navbar.scss"

const Navbar = ({ setShowContent = () => {}, solid = false }) => {
  const [showNavbarDrawer, setShowNavbarDrawer] = useState(false)

  const handlerOpenrNavDrawer = () => {
    setShowContent(true)
    setShowNavbarDrawer(!showNavbarDrawer)
  }

  const vh100 = window.innerHeight
  const [hideNavbar, setHideNavbar] = useState(true)

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset
    const offsetIsElderly = currentScrollPos <= vh100 - 76
    setHideNavbar(showNavbarDrawer ? false : offsetIsElderly)

    setScrollPosition(currentScrollPos)
  }, [showNavbarDrawer, vh100])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrollPosition, handleScroll])

  //

  return (
    <>
      <nav className="Navbar Navbar" style={{ top: hideNavbar ? "-24%" : 0, transition: "top .5s ease" }}>
        <div className="Navbar__logo">
          <a href="#">
            {!hideNavbar ? (
              <img src={logo} alt="logo" data-aos="fade-down" data-aos-easing="ease-in-out-quart" data-aos-duration="500" />
            ) : (
              <img src={logo} alt="logo" data-aos="fade-up" data-aos-easing="ease-in-out-quart" data-aos-duration="500" />
            )}
            {/* <img src={logo} alt="logo" className={!hideNavbar ? "animation-image" : ""} /> */}
          </a>
        </div>
        <div className="Navbar__options">
          {/* <ul className={!hideNavbar ? "animation-item" : ""}> */}
          {!hideNavbar ? (
            <ul data-aos="fade-down" data-aos-easing="ease-in-out-quart" data-aos-duration="1000">
              {menuItems.map((item, i) => (
                <li key={i} className={`${item.className}-navbar`}>
                  <a {...item} />
                </li>
              ))}
            </ul>
          ) : (
            <ul data-aos="fade-up" data-aos-easing="ease-in-out-quart" data-aos-duration="1000">
              {menuItems.map((item, i) => (
                <li key={i} className={`${item.className}-navbar`}>
                  <a {...item} />
                </li>
              ))}
            </ul>
          )}
        </div>

        <button onClick={() => handlerOpenrNavDrawer()} className="Navbar__Hamburger">
          <Hamburger color="#fefffb" toggled={showNavbarDrawer} />
        </button>
      </nav>
      <NavbarDrawer handlerOpenrNavDrawer={handlerOpenrNavDrawer} showNavbarDrawer={showNavbarDrawer} />
    </>
  )
}

export default Navbar

import { ArrowForward } from "@mui/icons-material"
import { Link } from "react-router-dom"
import Checkbox from "../../../../components/inputs/Checkbox"
import FormControl from "../../../../components/inputs/FormControl"
import InputText from "../../../../components/inputs/InputText"
import Button from "../buttons/Button"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import "./style.scss"
import { useEffect, useState } from "react"
import { sendForm } from "../../../../services/contactForm"
const inputs = [
  {
    label: "Nombre completo",
    name: "name",
    placeholder: "Juan Pérez",
  },
  {
    label: "Teléfono",
    name: "phone",
    placeholder: "+598",
  },
  {
    label: "Correo electrónico",
    name: "email",
    placeholder: "juanperez@ejemplo.com",
  },
  {
    label: "Tipo de consulta",
    name: "queryType",
    placeholder: "Cuéntanos cómo te ayudamos",
  },
  {
    label: "Mensaje",
    name: "message",
    textArea: true,
    placeholder: "Escribe tu mensaje aquí",
  },
]

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Ingresa tu nombre").min(3, "Ingresa un nombre más largo"),
  phone: Yup.string()
    .required("Ingresa tu teléfono")
    .matches(/^(?:[+\d].*\d|\d){8,}$/, "Formato incorrecto"),
  email: Yup.string().required("Ingresa tu email").email("Formato de correo incorrecto"),
  queryType: Yup.string().required("Agrega un mensaje").min(10, "Agrega un mensaje más largo"),
  message: Yup.string().required("Agrega un mensaje").min(10, "Agrega un mensaje más largo"),
})
const formOptions = { resolver: yupResolver(validationSchema) }

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm(formOptions)

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [loading, setLoading] = useState(false)

  const submitData = async (form) => {
    if (!acceptTerms) {
      setError("terms", {
        message: "Debes aceptar la política de privacidad",
      })
      return
    }

    setLoading(true)

    const res = await sendForm(form)
    if (res) {
      reset()
    }
    setLoading(false)
  }

  useEffect(() => {
    acceptTerms && clearErrors("terms")
  }, [acceptTerms, clearErrors])

  return (
    <div className="contact-form" id="Contacto">
      <form className="wrapper" onSubmit={handleSubmit(submitData)}>
        <h2 className="landing__title" data-aos="fade-up">
          Contacto
        </h2>
        <div className="contact-form__inputs">
          {inputs.map(({ label, name, textArea = false, ...rest }, i) => (
            <FormControl id={`${name}${i}`} key={i} label={label} error={!!errors[name]} HelperText={errors[name]?.message} required>
              <InputText
                id={`${name}${i}`}
                name={name}
                textArea={textArea}
                {...rest}
                {...register(name, {
                  required: true,
                })}
                disabled={loading}
              />
            </FormControl>
          ))}
        </div>
        <div className="contact-form__footer">
          <FormControl id={"terms"} error={!!errors?.terms} HelperText={errors?.terms?.message}>
            <Checkbox
              id={"terms"}
              label={() => (
                <span className="contact-form__checkbox__label">
                  Estoy de acuerdo con la{" "}
                  <Link to="/">
                    <b>Política de privacidad</b>
                  </Link>
                </span>
              )}
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(!!e?.target?.checked)}
            />
          </FormControl>

          <Button variant="contained" disabled={loading}>
            Enviar mensaje <ArrowForward />
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm

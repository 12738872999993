// Libraries
import React, { useEffect, useMemo, useState } from "react"
import Aos from "aos"
import Slider from "react-slick"

// Components
import Button from "../buttons/Button"

// Assets
import codigo_etica from "../../../../assets/files/codigo_etica.pdf"
import incripcion_bcu from "../../../../assets/files/incripcion_bcu.pdf"
import formulario_reclamos from "../../../../assets/files/formulario_reclamos.pdf"

// Styles
import "./style.scss"

const options = [
  {
    label: "Código de ética",
    title: "Nuestro código de ética",
    content: "<span>El presente Código de Conducta, Ética y Buenas Prácticas establece los principios y valores éticos que rigen el accionar de Globalfolio.</span>",
    fileName: "Código de Conducta Ética y Buenas Prácticas",
    file: codigo_etica,
    btnText: "LEER MÁS...",
    download: false,
  },
  {
    label: "Inscripción en el BCU",
    title: "Inscripción en el BCU",
    content: "<span>Autorización para funcionar como Gestor de Portafolios otorgada por el BCU.</span>",
    fileName: "Comunicación Licencia BCU",
    file: incripcion_bcu,
    btnText: "VER INSCRIPCIÓN",
    download: false,
  },
  {
    label: "Formulario de Reclamos",
    title: "Formulario de Reclamos",
    content:
      "<span>Puedes enviarlo via mail a: <br /><strong><a href='mailto:ftorres@globalfolio.com.uy'>ftorres@globalfolio.com.uy</a></strong> <br/><br /> O dejarlo en las oficinas de Globalfolio de lunes a jueves de 10:00 a 16:00 horas</span>",
    fileName: "Formulario de Reclamos",
    file: formulario_reclamos,
    btnText: "DESCARGAR FORMULARIO",
    download: true,
  },
]

export default function Index() {
  const [option, setOption] = useState(0)
  const [slider, setSlider] = useState(null)

  const changeOption = (option) => {
    setOption(option)
    slider?.slickGoTo(option)
  }

  const settings = useMemo(() => {
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2.6,
      slidesToScroll: 1,
      slide: option,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1.6,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return settings
  }, [option])

  const selectedOption = useMemo(() => {
    return options[option || 0]
  }, [option])
  useEffect(() => {
    Aos.refreshHard()
  }, [selectedOption])

  useEffect(() => {
    try {
      document.getElementsByClassName("Legales-footer-ethic")[0].addEventListener("click", () => setOption(0))
      document.getElementsByClassName("Legales-footer-bcu")[0].addEventListener("click", () => setOption(1))
      document.getElementsByClassName("Legales-footer-claims")[0].addEventListener("click", () => setOption(2))
      document.getElementsByClassName("Legales-navbar-claims")[0].addEventListener("click", () => setOption(2))
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div id="Legales" className="ethic">
      <div className="wrapper">
        <h2 className="landing__title" data-aos="fade-up">
          Legales
        </h2>
      </div>
      {/* Selector mobile */}
      <div className="ethic__slider">
        <Slider {...settings} ref={setSlider}>
          {options.map(({ label }, i) => (
            <p key={i} className={`ethic__slider__option ${option === i ? "" : "ethic__slider__option-active"}`} onClick={() => changeOption(i)}>
              {label}
            </p>
          ))}
        </Slider>
      </div>
      {/*  */}
      <div className="wrapper">
        <div className="ethic__container">
          {/* selector desktop */}
          <div className="ethic__container-wrap" data-aos="fade-right">
            <div className="ethic__container-wrap-left">
              <div className="ethic__container-wrap-left-buttons">
                {options.map(({ label }, i) => (
                  <button key={i} onClick={() => changeOption(i)} className={`ethic__container-wrap-left-buttons-btn ${option === i ? "active" : ""}`}>
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/*  */}
          <div className="ethic__container-wrap">
            {selectedOption && (
              <div className="ethic__container-wrap-right" data-aos="fade-left">
                <h5 className="ethic__container-wrap-right-title">{selectedOption.title}</h5>
                <p className="ethic__container-wrap-right-description" dangerouslySetInnerHTML={{ __html: selectedOption.content }} />
                {selectedOption.file && (
                  <>
                    {selectedOption.download ? (
                      <a href={selectedOption.file} download={selectedOption.fileName}>
                        <Button>{selectedOption.btnText}</Button>
                      </a>
                    ) : (
                      <a href={selectedOption.file} target={"_blank"} rel="noreferrer">
                        <Button>{selectedOption.btnText}</Button>
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

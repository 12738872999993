import "./TeamCard.scss"

const TeamCard = ({ index, member }) => {
  return (
    <div className="TeamCard">
      <div className="TeamCard__image-container" data-aos={`${index % 2 === 0 ? "fade-right" : "fade-left"}`}>
        <img src={member?.image} alt={`${member?.name} ${member?.lastname}`} />
      </div>
      <div data-aos={`${index % 2 === 0 ? "fade-left" : "fade-right"}`}>
        <h4 className="TeamCard__name">
          {member?.name} <span>{member?.lastname}</span>
        </h4>
        <p className="TeamCard__description">{member?.description}</p>
      </div>
    </div>
  )
}

export default TeamCard

import "./style.scss"

const WhatWeDo = () => {
  return (
    <div className="what-we-do" id="QueHacemos">
      <div className="wrapper">
        <h2 className="landing__title" data-aos="fade-up">
          ¿Qué hacemos?
        </h2>
        <h3 data-aos="fade-up">Globalfolio es un gestor de bienes patrimoniales y consultor global de inversiones.</h3>
        <h4 data-aos="fade-up">Estamos enfocados en las necesidades del cliente, entregando un servicio de excelencia, con alta disponibilidad y estándares éticos.</h4>
      </div>
    </div>
  )
}

export default WhatWeDo

// Libraries
import React from "react"
import Navbar from "../../components/Navbar/Navbar"

// Components
import {
  Home,
  Ethic,
  Map,
  Team,
  Footer,
  // ContactForm,
  WhatWeDo,
} from "./components"

// Styles
import "./style.scss"

export default function Index() {
  return (
    <>
      <Navbar />
      <div className="landing">
        <div className="landing__fullscreen">
          <div className="landing__home">
            <Home />
          </div>
          <WhatWeDo />
          <Team />
          <Map />
          {/* <ContactForm /> */}

          <Ethic />
          <Footer />
        </div>
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-has-content */
// Libraries
import { useEffect } from "react"

// Assets
import logo from "../../../../assets/images/landing/logo.svg"
// import rowImage from "../../../../assets/images/landing/row.png";

// Styles
import "./style.scss"

export const menuItems = [
  {
    className: "QueHacemos",
    href: "#QueHacemos",
    children: "¿Qué hacemos?",
  },
  {
    className: "Equipo",
    href: "#Equipo",
    children: "Nuestro equipo",
  },
  {
    className: "Ubicacion",
    href: "#Ubicacion",
    children: "Ubicación y contacto",
  },
  {
    className: "Legales",
    href: "#Legales",
    children: "Legales",
  },
]

export default function Index() {
  // const [lang, setLang] = useState(1);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const element = document.getElementsByClassName("home__blend")[0]
      // .css("opacity", 1 - window.scrollTop() / 700)
      const top = window.pageYOffset || document.documentElement.scrollTop
      element.style.opacity = 1 - top / 600
    })
  }, [])

  return (
    <div className="home">
      <div className="home__blend" />
      <img src={logo} alt="Globalfolio" className="home__logo" data-aos="fade-down" data-aos-easing="ease-in-out-quart" data-aos-duration="1000" />
      {/* <h1 className="home__title">Conoce a GlobalFolio Uruguay</h1> ease-in-out-cubic */}
      {/* <h3 className="home__subtitle">Estamos invirtiendo en nuestro sitio, muy pronto podrás conocer todo lo que hacemos.</h3> */}
      <ul className="home__buttons">
        {menuItems.map((item, i) => (
          <li key={i} className={`${item.className}-home`}>
            <a {...item} />
          </li>
        ))}
      </ul>
    </div>
  )
}

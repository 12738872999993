// Libraries

// Assets
import { ReactComponent as UpLogo } from "../../../../assets/images/landing/upLogo.svg"
import logo from "../../../../assets/images/landing/navbarLogo.svg"
import footer from "../../../../assets/images/landing/footer/footer3.png"

// Style
import "./style.scss"

export default function index() {
  return (
    <div className="footer">
      <div className="footer__image" data-aos="fade-up">
        <img src={footer} alt="footer" />
      </div>
      <div className="footer__container wrapper">
        <div className="footer__container-column" data-aos="fade-up">
          <img src={logo} alt="Globalfolio" />
          <h3>
            INVERSIONES GLOBALES <br />
            EL MUNDO A TU ALCANCE
          </h3>
        </div>
        <div className="footer__container-column" data-aos="fade-up">
          <div className="footer__container-column-items">
            <ul>
              <li>
                <a href="#QueHacemos">ACERCA DE GLOBALFOLIO</a>
              </li>
              <li>
                <a href="#Equipo" className="Equipo-footer">
                  NUESTRO EQUIPO
                </a>
              </li>
              <li>
                <a href="#Ubicacion">UBICACIÓN</a>
              </li>
              <li>
                <a href="#Legales">LEGALES</a>
              </li>
              <li className="Legales-footer-ethic">
                <a href="#Legales">CÓDIGO DE ÉTICA</a>
              </li>
              <li className="Legales-footer-bcu">
                <a href="#Legales">INSCRIPCIÓN EN EL BCU</a>
              </li>
              <li className="Legales-footer-claims">
                <a href="#Legales">FORMULARIO DE RECLAMOS</a>
              </li>
              <li>
                <a href="#Contacto">CONTACTO</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__container-column" data-aos="fade-up">
          <div className="footer__container-column-items">
            <span>
              <a href="https://www.google.com/maps/place/Zonamerica/@-34.7912509,-56.0731899,17z/data=!3m1!4b1!4m5!3m4!1s0x95a0290108ed868d:0xca8e3961ebff487f!8m2!3d-34.7912509!4d-56.0710012">
                Ruta 8 Km 17.500, Zonamerica, Edificio Celebra, Oficina 203, Montevideo, Uruguay.
              </a>
            </span>
            <span>
              Teléfono: <a href="tel:+59825182503">+598 2518 2503</a>
            </span>
            <span>
              <a href="mailto:contacto@globalfolio.com.uy">CONTACTO@GLOBALFOLIO.COM.UY</a>
            </span>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom-content wrapper">
          <span>
            NÚMERO DE LICENCIA DE GESTOR DE PORTAFOLIO OTORGADO POR EL BCU: <strong>4244</strong>
          </span>
          <span className="footer__bottom-content-developed">
            <strong>{new Date().getFullYear()}. Developed by</strong>
            <UpLogo />
          </span>
        </div>
      </div>
    </div>
  )
}

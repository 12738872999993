// Libraries
import React from "react";
import ReactDOM from "react-dom/client";
import AOS from "aos";

// Styles
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

// Components
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer theme="colored" position="top-center" hideProgressBar />
  </React.StrictMode>
);

reportWebVitals();

// import PropTypes from "prop-types";

import { forwardRef } from "react"

const InputText = forwardRef(({ textArea = false, ...props }, ref) =>
  textArea ? (
    <textarea {...props} ref={ref} id={props.id || props.name || ""} className={`${props?.className || ""} InputText`} formNoValidate rows={props.rows || 3} data-aos="fade-up" />
  ) : (
    <input {...props} ref={ref} id={props.id || props.name || ""} className={`${props?.className || ""} InputText`} formNoValidate data-aos="fade-up" />
  )
)

InputText.displayName = "InputText"
// InputText.propTypes = {};

export default InputText

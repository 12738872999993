/* eslint-disable react-hooks/exhaustive-deps */
// LIBRARES
import React from "react"
import { Route } from "react-router-dom"

const RouteWrapper = (props) => {
  // PROPS
  const { component: Component, layout: Layout, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default RouteWrapper

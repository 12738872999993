import React, { forwardRef } from "react"
import { classIf } from "../../../../utils/classNames"
import "./Button.scss"
const Button = forwardRef(({ children, variant = "outlined", className = "", ...rest }, ref) => {
  return (
    <button className={`Button ${classIf(variant === "contained", "Button__contained")} ${className}`} ref={ref} data-aos="fade-up">
      {children}
    </button>
  )
})

export default Button
